var render = function render(){
  var _vm$value, _vm$value2, _vm$value2$thumb, _vm$value3, _vm$value4, _vm$value4$thumb, _vm$value5, _ref3, _ref3$format, _vm$value6, _vm$value6$mini, _ref4, _ref4$format, _vm$value7, _vm$value7$mini, _vm$value8, _vm$value8$mini, _vm$value9, _vm$value9$mini, _vm$value10, _vm$value10$mini;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('v-sheet', [_c('v-row', {
    staticClass: "ma-n8px ma-lg-n24px"
  }, [_c('v-col', {
    staticClass: "pa-8px pa-lg-24px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "profile-avatar"
  }, [_c('v-avatar', {
    staticClass: "d-lg-none",
    attrs: {
      "size": "120"
    }
  }, [(_vm$value = _vm.value) !== null && _vm$value !== void 0 && _vm$value.thumb ? _c('v-img', {
    attrs: {
      "src": (_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$thumb = _vm$value2.thumb) === null || _vm$value2$thumb === void 0 ? void 0 : _vm$value2$thumb.src
    }
  }) : _c('v-img', {
    attrs: {
      "src": "/images/minishop/profile-default.png"
    }
  })], 1), _c('v-avatar', {
    staticClass: "d-none d-lg-inline-flex",
    attrs: {
      "size": "160"
    }
  }, [(_vm$value3 = _vm.value) !== null && _vm$value3 !== void 0 && _vm$value3.thumb ? _c('v-img', {
    attrs: {
      "src": (_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : (_vm$value4$thumb = _vm$value4.thumb) === null || _vm$value4$thumb === void 0 ? void 0 : _vm$value4$thumb.src
    }
  }) : _c('v-img', {
    attrs: {
      "src": "/images/minishop/profile-default.png"
    }
  })], 1), _vm.isMe ? _c('mini-profile-edit', {
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-small white",
          attrs: {
            "elevation": "4",
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("edit")])], 1)];
      }
    }], null, false, 259379834)
  }) : _vm._e()], 1)]), _c('v-col', {
    staticClass: "pa-8px pa-lg-24px"
  }, [_c('v-row', {
    staticClass: "ma-n4px ma-lg-n8px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-4px pa-lg-8px",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "tit tit--xxs"
  }, [_vm._v(_vm._s((_vm$value5 = _vm.value) === null || _vm$value5 === void 0 ? void 0 : _vm$value5.nickname))])]), _c('v-col', {
    staticClass: "pa-4px pa-lg-8px",
    attrs: {
      "cols": "auto",
      "order-lg": "3"
    }
  }, [_vm.isCompany ? _c('mini-seller-info', {
    attrs: {
      "value": _vm.value
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-small",
          attrs: {
            "color": "secondary"
          }
        }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
          attrs
        }), false), on), [_vm._v("AS 협력점 정보")])];
      }
    }], null, false, 762962812)
  }) : _vm._e()], 1), _vm.hasCompanyScope && _vm.isMe ? _c('v-col', {
    staticClass: "pa-4px pa-lg-8px",
    attrs: {
      "cols": "auto",
      "order-lg": "4"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-small",
    attrs: {
      "href": "/console"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("관리자 페이지")])], 1) : _vm._e(), _c('v-col', {
    staticClass: "pa-4px pa-lg-8px",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mr-4px"
  }, [_vm._v("TODAY")]), _c('span', {
    staticClass: "txt--dark font-weight-bold"
  }, [_vm._v(" " + _vm._s((_ref3 = ((_vm$value6 = _vm.value) === null || _vm$value6 === void 0 ? void 0 : (_vm$value6$mini = _vm$value6.mini) === null || _vm$value6$mini === void 0 ? void 0 : _vm$value6$mini.todayCount) || 0) === null || _ref3 === void 0 ? void 0 : (_ref3$format = _ref3.format) === null || _ref3$format === void 0 ? void 0 : _ref3$format.call(_ref3)) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mr-4px"
  }, [_vm._v("TOTAL")]), _c('span', {
    staticClass: "txt--dark font-weight-bold"
  }, [_vm._v(" " + _vm._s((_ref4 = ((_vm$value7 = _vm.value) === null || _vm$value7 === void 0 ? void 0 : (_vm$value7$mini = _vm$value7.mini) === null || _vm$value7$mini === void 0 ? void 0 : _vm$value7$mini.totalCount) || 0) === null || _ref4 === void 0 ? void 0 : (_ref4$format = _ref4.format) === null || _ref4$format === void 0 ? void 0 : _ref4$format.call(_ref4)) + " ")])])], 1)], 1)], 1), _c('div', {
    staticClass: "pt-6px py-lg-12px"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [!_vm.isMe ? _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('follow-btn', {
    attrs: {
      "params": {
        _follower: _vm.value._id,
        followings: _vm.followings
      }
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var active = _ref5.active,
          toggle = _ref5.toggle,
          loading = _ref5.loading;
        return [!active ? _c('v-btn', _vm._b({
          staticClass: "product-item__favorite v-btn--width-fixed w-100",
          on: {
            "!click": function ($event) {
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        }, 'v-btn', Object.assign({}, _vm.btn_tertiary, {
          loading
        }), false), [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v(" 팔로우 ")], 1) : _c('v-btn', _vm._b({
          staticClass: "v-btn--width-fixed w-100",
          on: {
            "!click": function ($event) {
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        }, 'v-btn', Object.assign({}, _vm.btn_tertiary, {
          loading
        }), false), [_vm._v("팔로우 취소")])];
      }
    }], null, false, 3561216357)
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    attrs: {
      "active-class": "false"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          path: `/minishop/${_vm.value._id}/follow`,
          query: {
            type: 'follower'
          }
        });
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_tertiary), false), [_vm._v("팔로워 " + _vm._s((_vm$value8 = _vm.value) === null || _vm$value8 === void 0 ? void 0 : (_vm$value8$mini = _vm$value8.mini) === null || _vm$value8$mini === void 0 ? void 0 : _vm$value8$mini.followCount))])], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xxs txt txt--sm d-none d-lg-block"
  }, [_c('v-col', [_vm._v(" " + _vm._s((_vm$value9 = _vm.value) === null || _vm$value9 === void 0 ? void 0 : (_vm$value9$mini = _vm$value9.mini) === null || _vm$value9$mini === void 0 ? void 0 : _vm$value9$mini.greeting) + " ")])], 1), _c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark d-none d-lg-flex"
  }, _vm._l(_vm.value.mini.tag.filter(function (item) {
    return item;
  }), function (tag, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" #" + _vm._s(tag) + " ")]);
  }), 1)], 1), _c('v-col', {
    staticClass: "pa-8px pa-lg-24px d-lg-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt"
  }, [_vm._v(" " + _vm._s((_vm$value10 = _vm.value) === null || _vm$value10 === void 0 ? void 0 : (_vm$value10$mini = _vm$value10.mini) === null || _vm$value10$mini === void 0 ? void 0 : _vm$value10$mini.greeting) + " ")])]), _c('v-col', {
    staticClass: "pa-8px pa-lg-24px d-lg-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, _vm._l(_vm.value.mini.tag.filter(function (item) {
    return item;
  }), function (tag, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" #" + _vm._s(tag) + " ")]);
  }), 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }