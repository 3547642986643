<template>
    <story-list-card outlined tile class="story-list-card px-12px px-lg-36px py-20px fill-height" v-bind="{ query, limit: 5 }" v-slot="{ items, loading }">
        <v-card-text class="fill-height d-flex flex-column justify-center pa-0">
            <v-data-table v-bind="{ items, headers, loading }" dense hide-default-footer disable-sort mobile-breakpoint="0" class="v-data-table--no-hover" @click:row="goStory">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }">
                    <span :key="index" v-html="header.formatter.bind(item)(value)" />
                </template>
                <template #[`header.createdAt`]>
                    <router-link :to="`/minishop/${query._writer}/story`"> 더보기 </router-link>
                </template>
            </v-data-table>
        </v-card-text>
    </story-list-card>
</template>

<script>
import { mapGetters } from "vuex";

import StoryListCard from "@/components/client/story/list/story-list-card.vue";

export default {
    components: {
        StoryListCard,
    },
    props: {
        query: { type: Object, default: () => ({}) },

        sort: { type: Object, default: () => ({ createdAt: -1 }) },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: 8 },
    },
    computed: {
        ...mapGetters("story", ["getCategory"]),

        headers() {
            return [
                {
                    text: "스토리",
                    value: "code",
                    formatter: (value) => `[${this.getCategory(value)?.name}]`,

                    width: 68,
                    class: "font-weight-bold font-size-14 font-size-lg-16",
                    cellClass: "font-weight-bold",
                },
                {
                    value: "subject",
                },
                {
                    text: "더보기",
                    value: "createdAt",
                    formatter: (value) => this.$dayjs(value).format("YY.MM.DD"),

                    align: "end",
                    class: "font-weight-regular font-size-12",
                    cellClass: "font-weight-regular font-size-12",
                },
            ];
        },
    },
    methods: {
        goStory(board) {
            this.$router.push(`/story/${board.code}/${board._id}`);
        },
    },
};
</script>


<style lang="scss" scoped>
::v-deep {
    .v-data-table__wrapper > table {
        & > thead > tr,
        & > tbody > tr {
            border-bottom: none !important;

            & > th,
            & > td {
                padding: 4px !important;
            }

            td {
                &:first-child {
                    text-wrap: nowrap;
                }
                &:nth-child(2) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }
        }

        & > tbody > tr {
            cursor: pointer;
        }
    }
}

.story-list-card {
    border-top-width: 0;
}
@media (min-width: 1024px) {
    .story-list-card {
        border-top-width: 1px;
        border-left-width: 0;
    }
}
</style>