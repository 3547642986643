<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="스토리" bg="/images/sub/story/visual.jpg">
                <template #path>
                    <visual-breadcrumbs />
                </template>
            </sub-visual-primary>
        </template>

        <template v-if="isMobile">
            <div style="margin: -40px 0 -80px">
                <v-divider />
                <slot />
            </div>
        </template>
        <template v-else>
            <page-section class="page-section--first page-section--last" containerSize="container--lg">
                <!-- <v-row class="row--contents flex-xl-nowrap"> -->
                <v-row class="flex-xl-nowrap">
                    <v-col v-if="$vuetify.breakpoint.xlOnly" cols="12" xl="auto">
                        <div class="w-xl-200px">
                            <!-- PC -->
                            <snb v-model="activeItem" title="스토리" v-bind="{ items }" />
                        </div>
                    </v-col>
                    <v-col class="page-section--contents">
                        <slot />
                    </v-col>
                </v-row>
            </page-section>
        </template>
    </client-page>
</template>

<script>
import { mapState } from "vuex";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import VisualBreadcrumbs from "@/components/client/inc/visual-breadcrumbs.vue";
import Snb from "@/components/client/list/snb.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        SubVisualPrimary,
        VisualBreadcrumbs,
        Snb,
    },
    props: {
        isMobile: { type: Boolean, default: false },
    },
    data: () => ({
        activeItem: undefined,
    }),
    computed: {
        ...mapState({
            scope: (state) => state.scope,
            items: function (state) {
                const { filterCategory } = this;
                return state.story.categories.filter(filterCategory).map(function formatCategory(category) {
                    let item = {
                        title: category.name,
                        to: { path: `/story/${category.code}` },
                        children: (category?.children || []).filter(filterCategory).map(formatCategory),
                    };

                    if (item.children.length < 1) delete item.children;
                    else
                        item.children = [
                            {
                                title: "전체보기",
                                to: item.to,
                            },
                            ...item.children,
                        ];

                    return item;
                });
            },
        }),
    },
    methods: {
        filterCategory({ scope__main = [] }) {
            if ((scope__main?.length || 0) == 0) return true;
            else return scope__main?.some?.((item) => (this.scope || []).includes(item));
        },
    },
};
</script>

<style lang="scss" scoped>
.page-section--contents {
    max-width: 1200px;
}
</style>