var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('story-page', _vm._b({}, 'story-page', {
    isMobile: _vm.isMobile
  }, false), [_vm.readable ? [_vm.mainType == _vm.STORY_MAIN_TYPES.ARTICLE.value ? [_c('story-main-article', _vm._b({}, 'story-main-article', {
    category: _vm.category
  }, false))] : _vm._e(), _vm.mainType == _vm.STORY_MAIN_TYPES.DEFAULT.value ? [_c('story-main-default', _vm._b({}, 'story-main-default', {
    category: _vm.category
  }, false))] : _vm._e(), _vm.mainType == _vm.STORY_MAIN_TYPES.GALLERY.value ? [_c('story-main-gallery', _vm._b({}, 'story-main-gallery', {
    category: _vm.category,
    detect: _vm.detect
  }, false))] : _vm._e(), _vm.mainType == _vm.STORY_MAIN_TYPES.SUMMARY.value ? [_c('story-main-summary', _vm._b({}, 'story-main-summary', {
    category: _vm.category,
    writable: _vm.writable,
    detect: _vm.detect
  }, false))] : _vm._e()] : [_vm._v(" 권한이 없습니다. ")]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }