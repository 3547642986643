<template>
    <mini-shop-page v-slot="{ user, isMe }">
        <v-container>
            <div class="tit-wrap tit-wrap--sm">
                <h2 class="tit tit--xxs">스케줄</h2>
            </div>
            <v-row justify="space-between">
                <v-col cols="12" lg="7">
                    <!-- <mini-date-picker v-bind="{ value: form, eventsArray: reservationEvents }" @input="change">
                        <template v-if="holidayBtn && isMe">
                            <v-btn v-bind="{ loading }" v-if="!form.excludedDates.includes(date)" @click="holidayToggle">휴무 등록</v-btn>
                            <v-btn v-bind="{ loading }" v-else @click="holidayToggle">휴무 취소</v-btn>
                        </template>
                    </mini-date-picker> -->
                    <schedule-calendar
                        v-bind="{
                            loading,
                            holidays: form.holidays,
                            eventsArray: reservationEvents,
                            excludedDates: form.excludedDates,
                        }"
                        v-on="{
                            holidayToggle,
                        }"
                        @input="change"
                    />
                </v-col>
                <v-col cols="12" lg="auto">
                    <v-divider class="d-lg-none"></v-divider>
                    <v-divider class="d-none d-lg-block" vertical></v-divider>
                </v-col>
                <v-col cols="12" lg="">
                    <div class="mb-18px mb-lg-24px">{{ dayjs(date||dayjs()).format("MM월 DD일(ddd)") }}</div>
                    <v-sheet class="select-time">
                        <v-btn v-for="(item, index) in schedulesWithReservation" :key="`${index}-schedule`" v-bind="{ loading }"
                        :class="!!item.reservation ? 'reserved' : '' "
                        @click="showDialog(item)"
                        >
                            {{ item?.startsAt }} ~ {{ item?.endsAt }}
                            <span v-if="isReservationAwaiting(item)"
                                class="accent2--text">&nbsp;(예약대기)</span>
                        </v-btn>
                        <mini-schedule-info v-bind="{ value: reservationDialog, seller: user, shows }" @input="close"/>
                    </v-sheet>
                    <div v-if="form.holidays.length" class="txt accent2--text mt-14px mt-lg-16px">매주 {{ holidays }} 휴무 
                    </div>
                    <div class="txt txt--sm line-height-2 mt-12px mt-md-16px">
                        <div>영업시간 : {{ form?.open?.startsAt }} ~ {{ form?.open?.endsAt }}</div>
                        <div v-if="form.breaks.length">휴게시간 : {{ breaks }}</div>
                    </div>
                    <div class="btn-wrap">
                        <mini-schedule-settings v-if="user?.type === USER_TYPES.COMPANY.value && isMe" v-bind="{ user, value: form }" @input="searchSchedule">
                            <template #activator="{ attrs, on }">
                                <v-btn v-bind="{ ...btn_secondary, attrs }" v-on="on">운영시간 설정</v-btn>
                            </template>
                        </mini-schedule-settings>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </mini-shop-page>
</template>

<script>
import { mapState } from "vuex";

import api from "@/api";
import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

import { btn_primary, btn_secondary, initSchedule, RESERVATION_STATUS, USER_TYPES } from "@/assets/variables";

import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";

import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
import MiniDatePicker from "@/components/client/miniShop/mini-date-picker.vue";
import MiniScheduleInfo from '@/components/client/miniShop/dialog/mini-schedule-info.vue';
import MiniScheduleSettings from "@/components/client/miniShop/dialog/mini-schedule-settings.vue";
import ScheduleCalendar from '@/components/client/miniShop/request/schedule-calendar.vue';

export default {
    components: {
        MiniShopPage,

        TabPrimary,
        MiniDatePicker,
        MiniScheduleInfo,
        MiniScheduleSettings,
        ScheduleCalendar,
    },
    data: () => ({
        dayjs,
        USER_TYPES,
        btn_primary,
        btn_secondary,
        holidayBtn: false,
        loading: false,
        shows:false,

        reservationDialog: null,

        //해당 일의 예약정보
        reservations: [],
        //해당 달력의 예약정보
        reservationEvents: [],

        date: null,
        form: initSchedule(),
        day: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
    }),
    computed: {
        ...mapState(["accessToken"]),
        _user() {
            return this.$route.params._user;
        },
        isMe() {
            return this.$store.state.payload._user === this._user;
        },
        holidays() {
            let sunday = this.form.holidays.indexOf("0");
            if(sunday !== -1) {
                this.form.holidays.splice(sunday, 1);
                this.form.holidays.sort((a, b) => parseInt(a) - parseInt(b));
                this.form.holidays.push("0");
                return this.form.holidays.map(day => this.day[day]).join(", ");
            } else{
                return this.form.holidays.sort((a, b) => parseInt(a) - parseInt(b)).map(day => this.day[day]).join(", ");
            }
        },
        breaks() {
            return this.form.breaks.map(time => `${time.startsAt} ~ ${time.endsAt}`).join("/ ")
        },
        schedulesWithReservation(){
            return this.form.reservationTime.map((item) => {
                let reservation = this.reservations.find(({ information, reservationStatus }) => {
                    return information.startsAt === item.startsAt 
                    && information.endsAt === item.endsAt 
                    && reservationStatus !== RESERVATION_STATUS.RESERVATION_CANCELED.value;
                });
                return { ...item, reservation };
            });
        },
    },
    methods: {
        async init(){
            this.date = this.$dayjs().format("YYYY-MM-DD");
            try{
                if(this.loading) return;
                else this.loading = true;

                await this.searchSchedule();
                await this.searchReservationsByDate();
                if(this.accessToken) await this.searchReservationsByEvents();
            } catch(error){
                this.$handleError(error);
            } finally{
                this.loading = false;
            }
        },
        // 해당 요일의 예약정보 조회
        async searchReservationsByDate() {
            try {
                let { reservations } = await api.v1.shop.reservations.gets({ 
                    params: { date: this.date, _seller: this._user }
                });
                this.reservations = reservations;
            } catch(error) {
                this.$handleError(error);
            }
        },
        // 관리자 & 비회원 = 해당 달의 예약 현황
        // 회원 = 자신의 예약 현황
        async searchReservationsByEvents() {
            try {
                const today = this.$dayjs().format("YYYY-MM-DD");
                const afterAMonth = this.$dayjs().add(1, "month").format("YYYY-MM-DD");
                let params = { _seller: this._user };
                params = this.isMe
                ? { ...params, dates: [ today, afterAMonth] } 
                : { ...params, _user: this.$store.state.payload._user };
                let reservations = (await api.v1.shop.reservations.gets({ params }))?.reservations || [];

                this.reservationEvents = reservations.map(({ information, _id})=> ({
                    _id,
                    date: this.$dayjs(information.date).format("YYYY-MM-DD"),
                }));

            } catch(error) {
                this.$handleError(error);
            };
        },
        // 미니숍 서비스 스케쥴 조회
        async searchSchedule(){
            try{
                let schedule = (await api.v1.shop.schedules.gets({ params: { _user: this._user }}))?.schedules?.[0] || {};
                this.form = initSchedule(schedule);
            } catch(error){
                this.$handleError(error);
            };
        },
        //관리자 - 휴무 등록 및 취소 버튼
        async holidayToggle(){
            if(this.loading) return;
            else this.loading = true;
            try {
                let { excludedDates } = this.form;

                excludedDates.includes(this.date) 
                    ? excludedDates = excludedDates.filter(item => item !== this.date)
                    : excludedDates.push(this.date);

                await api.v1.shop.schedules.put({...this.form, excludedDates});

                this.loading = false;
                await this.init();
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        isReservationAwaiting(item){
            if(item?.reservation?.reservationStatus === RESERVATION_STATUS.RESERVATION_AWAITING.value) return true;
            else return false;
        },
        change(date){
            this.holidayBtn = true;
            this.date = date;
        },
        showDialog(item){
            if(!!item.reservation){
                this.reservationDialog = this.reservationEvents.find(({ _id })=> _id === item.reservation._id);
                this.shows = !!this.reservationDialog;
            }
            // //방문자
            // if(!this.isMe) {
            //     this.shows = this.reservationsEvents.includes(({ _id })=> _id === item.reservation._id);
            // } else {
            //     this.shows = true;
            // };
        },
        close(value){
            this.shows = value;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        date(){
            this.searchReservationsByDate();
        },
        shows(){
            this.searchReservationsByDate();
            if(this.accessToken) this.searchReservationsByEvents();
        }
    }
};
</script>

<style lang="scss" scoped>
.select-time {
    flex-wrap: wrap;
    border-bottom: 1px solid var(--border-color);
    .v-btn {
        width: 100%;
        border: 1px solid var(--border-color);
        border-radius: 0 !important;
        border-bottom-width: 0 !important;
        background-color: #fff !important;
        color: var(--v-primary-base);
        font-weight: 500 !important;
        &.reserved {
            background-color: var(--v-grey-lighten5) !important;
            color: var(--v-grey-base) !important;
        }
    }
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: var(--border-color) !important;
}
</style>