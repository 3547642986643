<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="판매점" bg="/images/sub/store/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <page-section containerSize="container--lg" class="page-section--first page-section--last">

            <store-category-nav />

            <v-divider dark style="border-width: 2px 0 0" class="mt-40px mt-lg-60px mb-20px" />
            <div class="tit-wrap">
                <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="auto">
                        <h3 class="tit tit--xxs">전동킥보드</h3>
                    </v-col>
                    <store-search-bar/>
                </v-row>
            </div>
            <v-row class="row--sm">
                <v-col v-for="(store,index) in stores" :key="index" cols="6" xl="4">
                    <store-profile :value="store">
                        <template #avatar>
                            <v-img v-if="store.thumb" :src="store.thumb.src" />
                            <v-img v-else src="/images/minishop/profile-default.png" />
                        </template>
                    </store-profile>
                </v-col>
            </v-row>
            <div class="pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page)=> $router.push({query: {...$route.query, page }})" />
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { COMPANY_CATEGORY_TYPES } from "@/assets/variables";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";

import StoreCategoryNav from "@/components/client/sub/store/store-category-nav.vue";
import StoreProfile from "@/components/client/sub/store/store-profile.vue";
import StoreSearchBar from "@/components/client/sub/store/store-search-bar.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        SubVisualPrimary,
        TabPrimary,
        StoreCategoryNav,
        StoreProfile,
        StoreSearchBar
    },
    data: () => ({
        limit: 12,
        summary: {
            totalCount: 0
        },

        stores: []
    }),
    computed: {
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "Store", to: { path: "/sub/store" } },
                // { text: "전동킥보드", to: { path: "/sub/store/kickboard"}},
                { text: "HOME" },
                { text: "Service" },
                { text: "전동킥보드"},
            ];
            if (this.activeItem) {
                items.push({
                    text: this.activeItem?.title, 
                    // to: this.activeItem?.to,
                });
            }
            return items.map((item) => ({ ...item, disabled: false }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;

            return { ...query, category: COMPANY_CATEGORY_TYPES.ELC_KICKBOARD.value };
        },
    },
    methods:{
        init(){
            this.search();
        },
        async search(){
            try{
                let { skip, limit, params } = this;
                let { summary, stores } = await api.v1.shop.stores.gets({
                    headers: { skip , limit },
                    params
                });

                this.summary = summary;
                this.stores = stores;
            } catch(error){
                this.$handleError(error);
            }
        }
    },
    mounted(){
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped></style>
