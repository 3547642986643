<script>
export default {
    beforeCreate(){
        parent?.window?.nicepayComplete?.(this.$route.query);
        opener?.window?.nicepayComplete?.(this.$route.query);

        // 네이버 페이(모바일)의 경우, response 바로 라우팅
        if(!parent?.window?.nicepayComplete?.()){
            const userAgent = navigator.userAgent.toLocaleLowerCase();

            const { _order, resCode, resMessage } = this.$route.query;
            this.$router.push({ path: "/shop/result", query: { _order, resCode, resMessage } });

            if(!userAgent.includes('chrome') && userAgent.includes('safari')) {
                return;   
            }
        };

        if(parent || opener) window.close();
    },
}
</script>