<template>
    <v-sheet>
        <v-row v-if="writable && isMobile" no-gutters>
            <v-col cols="12">
                <v-btn tile :to="`./${category.code}/write`" width="100%" v-bind="{ ...attrs_input }">리뷰 작성</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <template v-for="category in categories">
                <v-col cols="12" lg="6" :key="category._id">
                    <summary-boards v-bind="{ category }" />
                </v-col>
            </template>
        </v-row>

        <v-divider dark class="my-40px my-md-70px my-lg-90px" />
        <common-board v-bind="{ category: commonCategory }" />

        <v-divider dark class="my-40px my-md-70px my-lg-90px" />
        <summary-riders v-bind="{ category: ridersCategory }" />
    </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

import { btn_txt, attrs_input } from "@/assets/variables";
import { initStoryCategory } from "@/store/story";

import CommonBoard from "./common/common-board.vue";
import SummaryBoards from "./summary/summary-boards.vue";
import SummaryRiders from "./summary/summary-riders.vue";

export default {
    components: {
        CommonBoard,
        SummaryBoards,
        SummaryRiders,
    },
    props: {
        category: { type: Object, default: initStoryCategory },
        writable: { type: Boolean, default: false },
        detect: { type: String, default: "" },
    },
    data: () => ({
        btn_txt,
        attrs_input,
    }),
    computed: {
        ...mapGetters("story", ["getCategory"]),
        categories() {
            return this.category?.children || [];
        },
        commonCategory() {
            return this.getCategory("article");
        },
        ridersCategory() {
            return this.getCategory("rider");
        },
        isMobile() {
            return this.detect === "mobile";
        },
    },
};
</script>

<style>
</style>