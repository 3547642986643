<template>
    <mini-shop-page v-bind="{ value: user, isMe}" @input="emit">
        <v-sheet class="mb-16px mb-20px">
            <v-row justify="space-between" align="center" class="row--xxs">
                <v-col cols="auto" v-if="isMe">
                    <follow-private-toggle :value="user"></follow-private-toggle>
                </v-col>
                <v-col cols="auto">
                    <v-row align="center" class="row--xxs">
                        <v-col cols="">
                            <v-text-field v-model="filter.searchValue" v-bind="{ ...attrs_input }" dense placeholder="검색어를 입력하세요" class="w-md-300px" clearable @keydown.enter="()=> { $router.push({query: {...$route.query, ...filter, page: 1}})}"/>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn v-bind="{ ...btn_primary }" small @click="()=> { $router.push({query: {...$route.query, ...filter, page: 1}})}">검색</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-sheet>

        <div class="tit-wrap tit-wrap--sm">
            <h3 class="tit tit--xxs">{{ title }}</h3>
        </div>
        <v-divider dark style="border-width: 2px 0 0" class="mb-30px" />
        <follow-list v-bind="{ value: user, isMe}" @input="emit"/>
    </mini-shop-page>
</template>

<script>
import api from "@/api";
import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";
import FollowList from "@/components/client/follow/follow-list.vue"
import FollowPrivateToggle from "@/components/client/follow/follow-private-toggle.vue";

import { attrs_input, btn_primary } from "@/assets/variables";

export default {
    components: {
        MiniShopPage,

        FollowPrivateToggle,
        FollowList
    },
    data: () => ({
        attrs_input,
        btn_primary,

        user: null,
        filter:{
            searchKey: "nickname",
            searchValue: null
        }
    }),
    computed: {
        // 블로그 주인
        _user(){
            return this.$route.params._user;
        },
        // 접속자
        isMe(){
            return this._user === this.$store.state.payload._user;
        },
        title(){
            return this.$route.query.type === "follower" ? "팔로워" : "팔로잉";
        }
    },
    methods:{
        init(){
            this.search();
        },
        async search(){
            try{
                let { user } = await api.v1.users.get({ _id: this._user });
                this.user = user;

            } catch(error){
                this.$handleError(error);
            }
        },
        emit(){
            this.search();
        }
    },
    mounted(){
        this.init();
    }
};
</script>