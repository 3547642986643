<template>
    <mini-shop-page>
        <div class="tit-wrap tit-wrap--sm">
            <h2 class="tit tit--xxs">판매점</h2>
        </div>

        <v-tabs v-model="tab" grow class="mb-40px mb-lg-60px">
            <tab-primary v-for="(tab, index) in tabItems" :key="index" :to="tab.to" exact>{{ tab.text }}</tab-primary>
        </v-tabs>
        <v-row class="row--sm">
            <v-col v-for="(service, index) in services" :key="index" cols="6" lg="3">
                <mini-request-card v-bind="{ value: service, to: `./service/${service._id}`}"/>
            </v-col>
        </v-row>
        <div class="pagination-wrap">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div>
    </mini-shop-page>
</template>

<script>
import api from "@/api";
import { COMPANY_CATEGORY_TYPES } from "@/assets/variables";
import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";

import MiniRequestCard from "@/components/client/miniShop/mini-request-card.vue";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
const types = Object.values(COMPANY_CATEGORY_TYPES);

export default {
    components: {
        MiniShopPage,

        TabPrimary,
        MiniRequestCard,
    },
    data: () => ({
        tab: null,
        types,

        services: null,
        summary: {
            totalCount: 0
        },
        limit: 8
    }),
    computed: {
        _user(){
            return this.$route.params._user;
        },
        type() {
            return this.$route.query.type;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;
            return { ...query };
        },
        tabItems() {
            return this.types.map((type)=> ({
                text: type.text,
                to : { path: './service', query: { ...this.params, type: type.value, page: this.page } },
                // active: this.type ===  type.value
            }));
        }
    },
    methods: {
        init(){
            if(!this.type) this.$router.push({ path: './service', query: { type: COMPANY_CATEGORY_TYPES.ELC_BICYCLE.value, page: this.page } });
            this.search();
        },
        async search(){
            if(this.loading) return;
            else this.loading = true;
            try {
                let { skip, limit, params } = this;
                let { summary, services } = await api.v1.shop.services.gets({ 
                    headers : { skip, limit },
                    params : { _user: this._user, ...params }
                });

                this.summary = summary;
                this.services = services;
            } catch(error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.query"() {
            this.init();
        },
        type() {
            this.init();
        }
    }
};
</script>