var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_vm.writable && _vm.isMobile ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "tile": "",
      "to": `./${_vm.category.code}/write`,
      "width": "100%"
    }
  }, 'v-btn', Object.assign({}, _vm.attrs_input), false), [_vm._v("리뷰 작성")])], 1)], 1) : _vm._e(), _c('common-title', _vm._b({
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [1 < _vm.categoryItems.length ? [_c('gallery-category', {
          attrs: {
            "value": _vm.category.code,
            "items": _vm.categoryItems
          }
        })] : _vm._e()];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('gallery-sort')];
      },
      proxy: true
    }])
  }, 'common-title', {
    title: _vm.title
  }, false)), _c('v-row', {
    staticClass: "row--sm"
  }, [_vm.items.length < 1 ? [_c('v-col', [_c('v-card-text', {
    staticClass: "text-center"
  }, [_vm._v(" 등록된 게시글이 없습니다 ")])], 1)] : _vm._e(), _vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item._id,
      attrs: {
        "cols": "6",
        "lg": "3"
      }
    }, [_c('gallery-item', {
      attrs: {
        "value": item
      }
    })], 1)];
  })], 2), _c('common-actions', _vm._b({
    staticStyle: {
      "margin": "80px -12px -80px"
    }
  }, 'common-actions', {
    page: _vm.page,
    pageCount: _vm.pageCount,
    loading: _vm.loading,
    writable: _vm.writable
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }