var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "판매점",
            "bg": "/images/sub/store/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('store-category-nav'), _c('v-divider', {
    staticClass: "mt-40px mt-lg-60px mb-20px",
    staticStyle: {
      "border-width": "2px 0 0"
    },
    attrs: {
      "dark": ""
    }
  }), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("전동킥보드")])]), _c('store-search-bar')], 1)], 1), _c('v-row', {
    staticClass: "row--sm"
  }, _vm._l(_vm.stores, function (store, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "xl": "4"
      }
    }, [_c('store-profile', {
      attrs: {
        "value": store
      },
      scopedSlots: _vm._u([{
        key: "avatar",
        fn: function () {
          return [store.thumb ? _c('v-img', {
            attrs: {
              "src": store.thumb.src
            }
          }) : _c('v-img', {
            attrs: {
              "src": "/images/minishop/profile-default.png"
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }