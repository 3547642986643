var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "tit tit--xxs",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_vm._v("문의")])], 1)], 1), _c('v-divider', {
    staticClass: "mb-6",
    staticStyle: {
      "border-width": "0 0 2px"
    },
    attrs: {
      "dark": ""
    }
  }), _c('comment-input', _vm._g(_vm._b({
    attrs: {
      "relative": "",
      "privatable": ""
    }
  }, 'comment-input', {
    isLoggedIn: _vm.isLoggedIn
  }, false), {
    save: _vm.save
  })), _c('v-divider', {
    staticClass: "mt-6",
    attrs: {
      "dark": ""
    }
  }), _c('comments', _vm._g({
    attrs: {
      "replyable": "",
      "privatable": ""
    },
    model: {
      value: _vm.comments,
      callback: function ($$v) {
        _vm.comments = $$v;
      },
      expression: "comments"
    }
  }, {
    save: _vm.save,
    remove: _vm.remove
  })), _c('v-pagination', {
    staticClass: "mt-6",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }