<template>
    <v-sheet>
        <v-tabs :show-arrows="false" grow class="my-40px">
            <template v-for="(item, index) in items">
                <tab-secondary v-bind="item" :class="`nav-tab-${index}`" :key="item.title">
                    {{ item.title }}
                    <v-menu :activator="`.nav-tab-${index}`" open-on-hover bottom nudge-bottom="62px" v-if="0 < item.children?.length || 0" :close-on-click="false" :close-on-content-click="false">
                        <v-list>
                            <template v-for="child in item.children">
                                <template v-if="child.type == 'story-category'">
                                    <v-list-item v-bind="child" :key="child.title" @click="$refs['manage-categories'].open()">
                                        <v-list-item-title class="text-center">
                                            <v-icon> mdi-folder-plus-outline </v-icon>
                                            {{ child.title }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                                <template v-else>
                                    <v-list-item v-bind="child" :key="child.title" :class="{ active: child.exact && child.active }">
                                        <v-list-item-title class="text-center">
                                            {{ child.title }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </template>
                        </v-list>
                    </v-menu>
                </tab-secondary>
            </template>
        </v-tabs>
        <mini-manage-categories v-bind="{ value }" ref="manage-categories" @input="$emit('input')" />
    </v-sheet>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { COMPANY_CATEGORY_TYPES, USER_TYPES } from "@/assets/variables";

import TabSecondary from "@/components/client/tab/tab-secondary.vue";

import MiniManageCategories from "./dialog/mini-manage-categories.vue";

export default {
    components: {
        TabSecondary,

        MiniManageCategories,
    },
    props: {
        isMe: { type: Boolean, default: false },
        value: { type: Object, default: () => ({}) },
    },
    computed: {
        ...mapState(["categories"]),
        ...mapGetters(["hasCompanyScope"]),
        items() {
            const items = [];

            const path = `/minishop/${this.value?._id}`;

            items.push(
                ...[
                    {
                        title: "미니샵 홈",
                        to: { path: `${path}` },
                        exact: true,
                    },
                    {
                        title: "베스트상품",
                        to: { path: `${path}/best` },
                        exact: true,

                        shows: this.value?.type == USER_TYPES.COMPANY.value,
                    },
                    {
                        title: "쇼핑",
                        to: { path: `${path}/category`, query: { category: this.categories[0]?.code } },
                        exactPath: true,

                        children: this.categories.map((category) => ({
                            title: category.name,
                            to: {
                                path: `${path}/category`,
                                query: { category: category.code },
                            },
                            active: this.$route.query.category === category.code,
                            exact: true,
                        })),

                        shows: this.value?.type == USER_TYPES.COMPANY.value,
                    },
                    {
                        title: "쿠폰",
                        to: { path: `${path}/coupons` },
                        exactPath: true,

                        shows: this.value?.type == USER_TYPES.COMPANY.value,
                    },
                    {
                        title: "스토리",
                        to: { path: `${path}/story` },

                        children: [
                            ...(this.value?.mini?.storyCodes || [])?.map?.((item) => ({
                                title: item.name,
                                to: { path: `${path}/story/${item.path}` },
                            })),
                            {
                                title: "일상 이야기",
                                to: { path: `${path}/story/daily` },
                            },
                            {
                                title: "라이더 이야기",
                                to: { path: `${path}/story/rider` },
                            },
                            {
                                title: "사장님 이야기",
                                to: { path: `${path}/story/owner` },
                                shows: !!this.hasCompanyScope,
                            },
                            ...(this.isMe
                                ? [
                                      {
                                          type: "story-category",
                                          title: "메뉴추가",
                                      },
                                  ]
                                : []),
                        ].filter((child) => child.shows ?? true )
                        .map((child) => ({
                            ...child,
                            active: this.$route.path.includes(child?.to?.path || ""),
                        })),
                    },
                    {
                        title: "팔로우",
                        to: { path: `${path}/follow`, query: { type: "follower" } },

                        children: [
                            {
                                title: "팔루워",
                                to: {
                                    path: `${path}/follow`,
                                    query: { type: "follower" },
                                },
                                active: this.$route.query.type == "follower",
                                exact: true,
                            },
                            {
                                title: "팔로잉",
                                to: {
                                    path: `${path}/follow`,
                                    query: { type: "following" },
                                },
                                active: this.$route.query.type == "following",
                                exact: true,
                            },
                        ],

                        // shows: this.value?.type == USER_TYPES.COMPANY.value,
                    },
                    {
                        title: "문의",
                        to: { path: `${path}/chat` },
                        exactPath: true,
                    },
                    {
                        title: "A/S신청",
                        to: { path: `${path}/request` },
                        children: [
                            {
                                title: "서비스",
                                to: {
                                    path: `${path}/request/service`,
                                    query: { type: COMPANY_CATEGORY_TYPES.ELC_BICYCLE.value, page: 1 }
                                },
                            },
                            {
                                title: "스케줄",
                                to: {
                                    path: `${path}/request/schedule`,
                                },
                            },
                        ],
                        shows: this.value?.type == USER_TYPES.COMPANY.value,
                    },
                ].map((item) => ({
                    ...item,
                    active: (item.children || []).some(({ active }) => active) || this.$route.path.includes(item?.to?.path || ""),
                }))
            );
            return items.filter((item) => item.shows ?? true);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    // .v-slide-group__wrapper {
    //     overflow: visible;
    // }
    .v-slide-group__next,
    .v-slide-group__prev {
        display: none !important;
    }
    .v-tab {
        text-transform: initial;
    }
    .active {
        background: rgb(236, 234, 234);
    }
}
</style>